import styled from '@emotion/styled'
import {
  Avatar,
  Button,
  ButtonLink,
  Card,
  FlexColumn,
  FlexRow,
  PhoneNumber,
  Text,
  colorTokens,
  colors,
  spacing,
  useAsyncCallback,
} from '@orus.eu/pharaoh'
import { useNavigate, useParams, useSearch } from '@tanstack/react-router'

import { TechnicalError } from '@orus.eu/error'
import { useCallback, useMemo } from 'react'
import { trpc } from '../../../client'
import partnerReferent from '../../../images/françois.png'
import { assert } from '../../../lib/errors'
import { useSession } from '../../../lib/session'
import { usePermissions } from '../../../lib/use-permissions'
import { useBuildUrl } from '../../../use-build-url'
import { BackofficeSectionTitle } from '../../atoms/backoffice-section-title'
import { backofficePartnerReferent, inferFirstName } from './backoffice-home-page.utils'
import { PartnersClientSignedEmailCheckbox } from './partners/partners-client-signed-email-checkbox'

export default function BackofficeHomePage(): JSX.Element {
  const { organization } = useParams({ strict: false })
  const { buildUrl } = useBuildUrl()
  const session = useSession()
  const { type: userType } = usePermissions()
  const isPartner = userType === 'partner'
  const urlSearchParams = useSearch({ strict: false })
  const navigate = useNavigate()
  const isEmbedInIframe = urlSearchParams.embedInIframe != null || window.self !== window.top

  const createSubscription = useAsyncCallback(async () => {
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({
      organizationShortName: organization,
    })
    if (isPartner && !organization)
      throw new TechnicalError('The connected user is partner and does not have an organization')

    const route =
      isPartner && organization
        ? {
            to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
            params: { subscriptionId, organization },
          }
        : {
            to: '/bak/v2-pending-subscriptions/$subscriptionId',
            params: { subscriptionId },
          }

    void navigate(route)
  }, [navigate, organization, isPartner])

  const navigateToClients = useCallback(() => {
    if (isPartner && !organization)
      throw new TechnicalError('The connected user is partner and does not have an organization')

    const route =
      isPartner && organization
        ? {
            to: '/partner/$organization/users',
            params: { organization },
          }
        : {
            to: '/bak/users',
          }

    void navigate(route)
  }, [navigate, organization, isPartner])

  assert(
    // eslint-disable-next-line no-unsafe-optional-chaining
    session.user && 'email' in session.user,
    'Backoffice users should have a verified email address',
  )

  const contractsCardAvatar = useMemo(() => {
    return (
      <Avatar
        variant="contained"
        size="60"
        icon="folder-light"
        color={colorTokens['color-stroke-info-active']}
        containerColor={colorTokens['color-bg-decorative-1']}
      />
    )
  }, [])

  const partnerReferentCardAvatar = useMemo(() => {
    return <Avatar variant="round" size="60" src={partnerReferent} imageBackgroundColor={colors.sky.light} />
  }, [])

  if (isEmbedInIframe) {
    return (
      <FlexColumn gap={spacing[70]} margin={`${spacing[90]}`} padding="0">
        <BackofficeSectionTitle>
          Bonjour {session.user.firstName ?? inferFirstName(session.user.email)} !
        </BackofficeSectionTitle>
        <Text>Vous êtes connecté au backoffice Orus, donc la création de devis doit y être effectué.</Text>
        <Text>Afin de créer un nouveau devis, veuillez suivre le lien ci-dessous :</Text>
        <FlexRow gap={spacing[70]}>
          <Button
            variant="secondary"
            onClick={() => {
              if (!window.top) return
              window.top.location.href = !organization
                ? buildUrl({ to: '/bak/pending-subscriptions' })
                : buildUrl({ to: '/partner/$organization/pending-subscriptions', params: { organization } })
            }}
          >
            Accès au backoffice
          </Button>
        </FlexRow>
      </FlexColumn>
    )
  }

  return (
    <HomePageContainer>
      <HomePageTitle>
        <Text variant="h3">Bonjour {session.user.firstName ?? inferFirstName(session.user.email)} 👋</Text>
        <Text variant="body1" color={colorTokens['color-text-base-basic']}>
          Votre espace en ligne pour gérer les contrats de vos clients.
        </Text>
      </HomePageTitle>

      <CardsContainer>
        <Card
          title="Contrats"
          subtitle="Retrouvez tous vos dossiers en un clic."
          avatar={contractsCardAvatar}
          subtitleColor={colorTokens['color-text-base-basic']}
          withBorder
        >
          <Button variant="primary" icon="plus-regular" avatarPosition="left" onClick={createSubscription} fullWidth>
            Nouveau devis
          </Button>
          <Button variant="secondary" icon="folder-regular" avatarPosition="left" onClick={navigateToClients} fullWidth>
            Voir les clients
          </Button>
        </Card>

        {isPartner && (
          <Card
            title="Équipe Orus"
            subtitle="Échangez avec votre référent personnel."
            avatar={partnerReferentCardAvatar}
            subtitleColor={colorTokens['color-text-base-basic']}
            withBorder
          >
            <ButtonLink
              variant="secondary"
              icon="envelope-regular"
              avatarPosition="left"
              to={`mailto:${backofficePartnerReferent.email}`}
              fullWidth
            >
              {backofficePartnerReferent.email}
            </ButtonLink>
            <ButtonLink
              variant="secondary"
              icon="phone-regular"
              avatarPosition="left"
              to={`tel:${backofficePartnerReferent.phone}`}
              fullWidth
            >
              <PhoneNumber>{backofficePartnerReferent.phone}</PhoneNumber>
            </ButtonLink>
          </Card>
        )}
      </CardsContainer>

      {isPartner && (
        <NotificationsContainer>
          <Text variant="body1Medium">Notifications </Text>
          <PartnersClientSignedEmailCheckbox />
        </NotificationsContainer>
      )}
    </HomePageContainer>
  )
}

const HomePageContainer = styled.div`
  margin: ${spacing[90]} ${spacing[100]};
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing[60]};
`

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
`

const HomePageTitle = styled.div`
  margin: ${spacing[60]} 0;
  display: flex;
  flex-direction: column;
  gap: ${spacing[20]};
`
