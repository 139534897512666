/**
 * Here we infer first name using email naming rule to greet our agents in a nicer way.
 * It can seem like it's not much, but was we see everyday matters !
 * @param email an orus email.
 */
export function inferFirstName(email: string): string {
  if (!email.endsWith('@orus.eu') && !email.endsWith('@orustest.eu')) {
    return ''
  }

  const beforeAt = email.split('@')[0]
  if (!beforeAt) {
    return ''
  }

  const firstName = beforeAt.split('.')[0]
  if (!firstName) {
    return ''
  }
  return firstName[0].toUpperCase() + firstName.slice(1)
}

export const backofficePartnerReferent = {
  email: 'françois.coste@orus.eu',
  phone: '07 57 90 16 99',
}
