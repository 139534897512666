import { CheckboxContainer, useAsyncCallback } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpc, trpcReact } from '../../../../client'

export const PartnersClientSignedEmailCheckbox = memo(function PartnersClientSignedEmailCheckbox() {
  const getMyNotificationsConfigurationApiCall =
    trpcReact.notificationsConfiguration.getMyPartnerNotificationsConfiguration.useQuery()

  const configuration = getMyNotificationsConfigurationApiCall.data
  const checked = configuration?.sendSignedClientEmail ?? false

  const handleChange = useAsyncCallback(
    async (value: boolean) => {
      if (!configuration) return
      await trpc.notificationsConfiguration.updateMyPartnerNotificationConfiguration.mutate({
        ...configuration,
        sendSignedClientEmail: value,
      })
      await getMyNotificationsConfigurationApiCall.refetch()
    },
    [configuration, getMyNotificationsConfigurationApiCall],
  )

  const loading = !getMyNotificationsConfigurationApiCall.data

  return (
    <CheckboxContainer size="small" checked={checked} onChange={handleChange} disabled={loading}>
      Être notifié par mail des contrats signés
    </CheckboxContainer>
  )
})
